<template>
  <div class="transfer-check-view">
    <div v-if="!$store.state.isMobileLayout" class="types">
      <div class="type-title">交易类型</div>
      <div
        v-for="(type, idx) in display_type_list"
        :key="idx"
        :class="['type-btn btn', { active: game_divide === type.game_divide }]"
        :value="type.game_divide"
        @click="change_type(type.game_divide)"
        v-html="type.text"
      ></div>
    </div>
    <div
      :class="`${
        $store.state.isMobileLayout ? 'full-content' : 'right-content'
      }`"
    >
      <div class="top flex-ac flex-jb">
        <month-picker @setDate="setDate"></month-picker>
        <div v-if="$store.state.isMobileLayout" class="control relative">
          <div
            class="btn active type-btn"
            @click="is_show_option = !is_show_option"
          >
            交易类型
          </div>
          <transition name="fade-up">
            <div v-if="is_show_option" class="option-modal">
              <div class="label">请&nbsp;选&nbsp;择</div>
              <div
                v-for="(type, idx) in display_type_list"
                :key="idx"
                :class="[
                  'option',
                  { active: game_divide === type.game_divide },
                ]"
                :value="type.game_divide"
                @click="change_type(type.game_divide)"
                v-html="type.text"
              ></div>
            </div>
          </transition>
        </div>
      </div>
      <a-spin :spinning="loading">
        <div v-if="list.length > 0" class="table">
          <div
            v-for="(item, idx) in list"
            :key="idx"
            class="table-row flex-ac flex-jb log-item"
          >
            <div class="detail">
              <p class="title" v-text="item.game_name"></p>
              <p
                :class="['amount', item.deposite > 0 ? 'red' : 'green']"
                v-text="item.deposite > 0 ? item.deposite : item.withdraw"
              ></p>
            </div>
            <div v-text="item.record_date"></div>
          </div>
        </div>
        <div v-else class="no-data">暫無數據</div>
        <div class="bottom">
          <div v-if="showPagination" class="pages flex-ac flex-jb">
            <!--
            <div class="page-btns flex">
              <div class="page-btn" @click="firstPage">首页</div>
              <div class="page-btn" @click="prevPage">上一页</div>
              <div class="page-btn" @click="nextPage">下一页</div>
              <div class="page-btn" @click="lastPage">末页</div>
            </div>
            <div class="page-index">
              第 {{ pages }} 页 共 {{ totalPages }} 页
            </div>
            -->
          </div>
        </div>
      </a-spin>
    </div>
  </div>
</template>

<script>
import { post } from '@/axios'
import MonthPicker from '@/components/month-picker.vue'
import pagination from '@/mixins/pagination'

export default {
  name: 'transfer_check',
  components: {
    //MonthPicker
  },
  mixins: [pagination],
  data() {
    return {
      game_divide: '',
      is_show_option: false,
      display_type_list: [
        {
          text: '全部',
          game_divide: ''
        }
      ],
      type_list: [
        {
          text: '全部',
          game_divide: ''
        },
        {
          text: '聊天室娱乐',
          game_divide: '1'
        },
        {
          text: 'AG娱乐',
          game_divide: '2'
        }
      ]
    }
  },
  async created() {
    await this.getData()
  },
  methods: {
    change_type(val) {
      this.game_divide = val
      this.is_show_option = false
      this.page = 1
    },
    async getData() {
      const mapStr = JSON.stringify({
        pages: this.pages,
        rows: 5
      })

      try {
        this.loading = true
        const { code, data, info } = await post(
          'record_scoreRecord.do',
          this.Qs.stringify({ mapStr })
        )
        if (code === 'fail') {
          this.$message.error(info, 2)
        } else {
          const { exchangeList, total_num } = JSON.parse(data)
          this.list = exchangeList
          this.total_num = total_num
        }
      } catch {
      } finally {
        this.loading = false
      }
    }
  },
  watch: {
    monthDate() {
      this.getData()
    },
    pages() {
      this.getData()
    },
    game_divide() {
      this.getData()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/style/function.scss';
@import '~@/assets/style/variables.scss';

.transfer-check-view {
  padding: 2rem 2%;
}

.btn.golden-bg {
  color: white;
  border: none;
  font-size: 1rem;
  margin-bottom: 1rem;
  background-image: url('~@/assets/images/member_center/btn-bg.png');
  padding: 0.35rem 0.7rem;
  border-radius: 0.5rem;
  cursor: pointer;
}

.option-modal {
  position: absolute;
  width: 100%;
  top: 2.2rem;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
  padding: 0.5rem;
  border-radius: 0.5rem;
  white-space: nowrap;

  .label {
    color: #999;
    font-size: 0.8rem;
    letter-spacing: 0.1rem;
    padding: 0.5rem;
  }

  .option {
    padding: 0.5rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.1rem;
    cursor: pointer;

    &.active {
      color: $theme-color;
    }
  }
}

.select {
  background: transparent;
  color: white;
  border: none;
  font-size: 1rem;
  letter-spacing: 0.1rem;
  margin-bottom: 1rem;
}

.top {
  width: 100%;
}

.table {
  width: 100%;
}

.types {
  width: 8rem;
  display: flex;
  flex-direction: column;
}

.type-title {
  color: white;
  margin: 0.5rem 0 1rem;
  font-size: 1rem;
}

.type-btn {
  color: white;
  margin-bottom: 0.8rem;
  cursor: pointer;
  letter-spacing: 10px;
}

.right-content {
  flex: 1;
  margin-left: 0.5rem;
}

.table-row {
  width: 100%;
  font-size: 0.8rem;
  margin-bottom: 1px;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > div {
    width: 100%;
    display: flex;
    justify-content: space-between;

    &:last-child {
      justify-content: flex-end;
      margin-top: 0.5rem;
    }
  }
}

.bottom {
  width: 100%;
  height: 6rem;
  position: relative;
  left: 0;
  right: 0;
  margin: 1rem auto 0;
  padding-bottom: 1rem;
}

.hint {
  width: 50%;
  margin: 0 auto 1rem;
  line-height: 1.5;
  font-size: 0.8rem;
  color: #999;
  text-align: left;
}

.pages {
  position: relative;
  font-size: 0.9rem;
  user-select: none;
}

.page-btns {
  width: 100%;
}

.page-btn {
  color: white;
  margin: 0 0.5rem;
  cursor: pointer;
  transition: color 0.3s;

  &:hover {
    color: #c9a67c;
  }
}

.page-index {
  width: 20%;
  color: white;
  position: absolute;
  top: 0;
  right: 0;
  text-align: right;
}

@include md {
  .select {
    font-size: 0.8rem;
  }

  .full-content {
    width: 100%;
  }

  .btn.type-btn {
    letter-spacing: 0.1rem;
  }

  .option-modal {
    border-radius: 5px;
    z-index: 1;

    .label {
      padding: 0;
      font-size: 0.7rem;
    }

    .option {
      font-size: 0.8rem;
    }
  }

  .table-row {
    background-color: #313131;
    color: #999;
    padding: 0.5rem;

    &:hover {
      background-color: #1d1d1d;
      color: white;
    }
  }

  .row-record {
    font-size: 0.7rem;
  }

  .bottom {
    width: 100%;
    position: relative;
    margin-top: 1rem;

    .hint {
      width: 100%;
    }
  }

  .pages {
    font-size: 0.8rem;
    display: block;
  }

  .page-btns {
    justify-content: center;
  }

  .page-index {
    width: 100%;
    margin-top: 0.5rem;
    position: relative;
    text-align: center;
  }
}
</style>
