import dayjs from 'dayjs'

export default {
  name: 'pagination',
  data() {
    return {
      loading: true,
      pages: 1,
      rows: 10,
      list: [],
      total_num: 0,
      monthDate: dayjs().format('YYYY-MM'),
    }
  },
  computed: {
    totalPages() {
      const num = this.total_num % this.rows
      return num > 0
        ? (this.total_num - num) / this.rows + 1
        : this.total_num / this.rows
    },
    showPagination() {
      return this.totalPages !== 0 && this.list.length > 0
    },
  },
  methods: {
    firstPage() {
      this.pages = 1
    },
    prevPage() {
      this.pages = this.pages === 1 ? this.totalPages : this.pages - 1
    },
    nextPage() {
      this.pages = this.pages >= this.totalPages ? 1 : this.pages + 1
    },
    lastPage() {
      this.pages = this.totalPages
    },
    setDate(date) {
      this.monthDate = date
      this.pages = 1
    },
  },
}
