<template>
  <a-config-provider :locale="zhCN">
    <a-month-picker
      :allowClear="false"
      :default-value="defaultValue"
      :format="monthFormat"
      @change="onChange"
    >
      <ArrowDownIcon slot="suffixIcon" />
    </a-month-picker>
  </a-config-provider>
</template>

<script>
import ArrowDownIcon from '@/assets/icons/arrow-down.svg'
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN'
// import enUS from 'ant-design-vue/es/locale/en_US'
import zhCN from 'ant-design-vue/es/locale/zh_CN'
import dayjs from 'dayjs'
// import 'dayjs/locale/zh-cn';
// dayjs.locale('en');

export default {
  name: 'month-picker',
  props: [],
  components: {
    ArrowDownIcon,
  },
  data() {
    return {
      locale,
      zhCN,
    }
  },
  methods: {
    async onChange(v) {
      const date = dayjs(v).format('YYYY-MM')
      this.$emit('setDate', date)
    },
  },
  computed: {
    defaultValue() {
      return dayjs().format('YYYY-MM')
    },
    monthFormat() {
      return 'YYYY 年 MM 月'
    },
  },
}
</script>

<!-- <style lang="scss" scoped>
@import '~@/assets/style/function.scss';
@import '~@/assets/style/variables.scss';
</style> -->
